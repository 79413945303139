import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Hello World!</h1>
      <h2>I am a great developer. 💪</h2>
    </div>
  );
}

export default App;
